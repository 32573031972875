import { useEffect } from "react";
import AOS from "aos";
import { Container } from "react-bootstrap";
import {
  SectionWrapper,
  SubTitle,
  ButtonWrap,
  StyledLink,
  TimeCountdown,
} from "./Styled";
import { BoldSectionTitle, ThinSectionTitle } from "components/SectionTitle";
import { ButtonRed, ButtonYellow } from "components/Button";
import { BOX_PRICE, TOTAL_BOX, R2_START_TIME, MAXIMUM_BUY, ROUND } from "../../constants";
import { numberWithCommas } from "../../utils";
import { ClaimButton } from "../PresalePurchase/Styled";
import Loader from "../../components/Loader";
import Countdown, { zeroPad } from "react-countdown";

function LandingPage(): JSX.Element {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <SectionWrapper>
      <Container>
        <div className="col col-lg-8 col-xl-7">
          <div className="mb-5">
            <ThinSectionTitle data-aos="fade-right" data-aos-delay="300">
              <span>
                Gen1 White List
                <br />
                Presale Round {ROUND}
              </span>
            </ThinSectionTitle>
            <BoldSectionTitle
              mb="3rem"
              data-aos="fade-right"
              data-aos-delay="350"
            >
              Mystery Boxes are LIVE
            </BoldSectionTitle>
            <SubTitle data-aos="fade-right" data-aos-delay="400">
              Mystery Boxes will be redeemable for a CasperPunk digital
              collectible at a future minting date.
            </SubTitle>
          </div>

          <BoldSectionTitle data-aos="fade-left" data-aos-delay="500">
            <span>Boxes for Sale : {numberWithCommas(TOTAL_BOX)}</span>
          </BoldSectionTitle>
          <TimeCountdown data-aos="fade-left" data-aos-delay="550">
            {/*
            // @ts-ignore */}
            <Countdown
              date={R2_START_TIME * 1000}
              zeroPadTime={2}
              renderer={(props2) =>
                props2.completed ? null : (
                  <span>
                    Starting in {zeroPad(props2.days)}d:{zeroPad(props2.hours)}h:
                    {zeroPad(props2.minutes)}m:
                    {zeroPad(props2.seconds)}s
                  </span>
                )
              }
            />
          </TimeCountdown>
          <div data-aos="fade-left" data-aos-delay="600">
            <SubTitle>Maximum {MAXIMUM_BUY} Mystery Boxes per wallet.</SubTitle>
            <SubTitle>
              Price: <span>{numberWithCommas(BOX_PRICE)}</span> CSPR
            </SubTitle>
          </div>

          <ButtonWrap>
            <ButtonYellow data-aos="fade-up" data-aos-delay="700">
              <StyledLink to="/eligibility">Check Eligibility</StyledLink>
            </ButtonYellow>
            <ButtonRed data-aos="fade-up" data-aos-delay="800">
              <StyledLink to="/purchase">Minting Experience</StyledLink>
            </ButtonRed>
          </ButtonWrap>
        </div>
      </Container>
    </SectionWrapper>
  );
}

export default LandingPage;
