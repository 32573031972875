import styled, { keyframes } from "styled-components/macro";
import { ButtonRed, ButtonHint } from "components/Button";
import { CgSpinner } from "react-icons/cg";

export const StyledForm = styled.form`
  transition: height 0.2s ease;
  position: relative;
  z-index: 1;
`;

export const FieldContainer = styled.div`
  display: grid;
  row-gap: 0.5rem;
  margin-top: 2rem;
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: left;
  margin: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 80%;

  @media (min-width: 576px) {
    width: 60%;
  }

  @media (min-width: 768px) {
    width: 46%;
  }

  @media (min-width: 992px) {
    width: 58%;
  }

  @media (min-width: 1200px) {
    width: 49%;
  }

  @media (min-width: 1400px) {
    width: 42%;
  }
`;

export const RequiredWrapper = styled.div`
  margin-top: 0.5rem;

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #000;
    text-align: left;
    margin: auto;
    margin-bottom: 0.5rem;
    width: 80%;

    @media (min-width: 576px) {
      width: 60%;
    }

    @media (min-width: 768px) {
      width: 46%;
    }

    @media (min-width: 992px) {
      width: 58%;
    }

    @media (min-width: 1200px) {
      width: 49%;
    }

    @media (min-width: 1400px) {
      width: 42%;
    }
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000;
  width: 80%;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 1rem;

  @media (min-width: 425px) {
    width: 70%;
    padding-left: 1rem;
  }

  @media (min-width: 576px) {
    width: 54%;
  }

  @media (min-width: 768px) {
    width: 41%;
  }

  @media (min-width: 992px) {
    width: 54%;
  }

  @media (min-width: 1200px) {
    width: 44%;
  }

  @media (min-width: 1400px) {
    width: 38%;
  }

  input {
    position: relative;
    margin-right: 1rem;
    flex: none;

    &::before {
      content: "";
      border: 2px solid #000;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  span {
    word-wrap: break-word;
  }

  a {
    font-weight: 700;
    color: #4592ae !important;
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;
  justify-content: center;
  row-gap: 1rem;
  margin-top: 2rem;
`;

export const StyledButton = styled(ButtonRed)<{ disabled?: boolean }>`
  padding: 0.75rem 3rem;
  opacity: ${({ disabled }) => disabled && "0.7"};
  cursor: ${({ disabled }) => !disabled && "pointer"};
`;

export const StyledButtonSecond = styled(ButtonHint)`
  padding: 0.75rem 1rem;

  img {
    width: 30px;
    margin-right: 10px;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(CgSpinner)`
  animation: ${spin} 0.75s linear infinite;
  margin-right: 0.5rem;
`;

export const StyledLink = styled.a`
  color: #fff;

  &:hover {
    color: #fff;
  }
`;
