import {SectionTitle} from "components/SectionTitle";
import {Container} from "react-bootstrap";
import {
  ContentTitle,
  TitleWrapper,
  SectionWrapper,
} from "./Styled";
import Content from './Content'

function PrivacyPolicy(): JSX.Element {
  return (
    <SectionWrapper>
      <Container>
        <TitleWrapper>
          <SectionTitle>Privacy Policy</SectionTitle>
        </TitleWrapper>

        <ContentTitle>Privacy Policy for Punk Games LTD.</ContentTitle>
        <Content/>
      </Container>
    </SectionWrapper>
  );
}

export default PrivacyPolicy;
