import axios from "axios";
import useSWR from "swr";
import { useCurrentNetwork } from "./useNetwork";

export const useGetMintedBox = (): any => {
  const currentNetwork = useCurrentNetwork();
  const fetcher = (url: string) => axios.get(url).then((res) => res.data.mintedBox);

  const apiUrl = `${currentNetwork?.boxApi}/getmintedbox?factoryContract=${currentNetwork?.contract.factoryHash}`;

  const { data, error } = useSWR(apiUrl, fetcher, {
    refreshInterval: 60000,
    refreshWhenHidden: true,
  });

  return { data, error };
};
