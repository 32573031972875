import { useEffect } from "react";
import AOS from "aos";
import Scroll from "react-scroll";
import { Container } from "react-bootstrap";
import { SectionTitle } from "components/SectionTitle";
import {
  Circle,
  ContentRow,
  ContentText,
  ContentTitle,
  ContentWrapper,
  SectionWrapper,
  TitleWrapper,
} from "./Styled";
import "aos/dist/aos.css";

function Section4(): JSX.Element {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <Scroll.Element name="roadmap">
      <SectionWrapper>
        <Container>
          <TitleWrapper>
            <SectionTitle data-aos="fade-left" data-aos-delay="300">
              Roadmap
            </SectionTitle>
          </TitleWrapper>
          <ContentWrapper>
            <ContentRow>
              <ContentTitle data-aos="flip-left" data-aos-delay="400">
                Now to 2023 Q1
                <Circle />
              </ContentTitle>
              <ContentText data-aos="fade-up" data-aos-delay="450">
                <ul>
                  <li>International Whitelist</li>
                  <li>
                    Generation 1 CasperPunks Digital Collectibles Made Available
                    To Purchase To Successful Whitelist Participants
                  </li>
                  <li>Marketplace Goes Live</li>
                  <li>Exciting 1st Brand Partnership Revealed</li>
                  <li>Whitepaper And Documentation</li>
                </ul>
              </ContentText>
            </ContentRow>
            <ContentRow>
              <ContentTitle data-aos="flip-left" data-aos-delay="450">
                2023 Q2
                <Circle />
              </ContentTitle>
              <ContentText data-aos="fade-up" data-aos-delay="500">
                <ul>
                  <li>CasperPunks Early Access Ecosystem-Pass Announced</li>
                  <li>
                    Your Own CasperPunk Mint-To-Merch Store Made Available With
                    Casper Wallet
                  </li>
                  <li>Ghost Town Game Revealed: Engage Your CasperPunk</li>
                  <li>CasperPunks Ghost DAO Overview</li>
                  <li>On-board Partnerships</li>
                </ul>
              </ContentText>
            </ContentRow>
            <ContentRow>
              <ContentTitle data-aos="flip-left" data-aos-delay="500">
                2023 Q3
                <Circle />
              </ContentTitle>
              <ContentText data-aos="fade-up" data-aos-delay="550">
                <ul>
                  <li>CasperPunks Scholarship Revealed</li>
                  <li>
                    Ghost DAO 2024 Feedback And Roadmap Proposal Submission
                  </li>
                  <li>Ghost Town Game Update</li>
                  <li>Ghost DAO Governance Portal Revealed</li>
                  <li>On-board Partnerships</li>
                </ul>
              </ContentText>
            </ContentRow>
            <ContentRow>
              <ContentTitle data-aos="flip-left" data-aos-delay="550">
                2023 Q4
                <Circle />
              </ContentTitle>
              <ContentText data-aos="fade-up" data-aos-delay="600">
                <ul>
                  <li>1st Birthday Celebration IRL/Metaverse Event</li>
                  <li>
                    Ghost DAO 2024 Roadmap And Community Leaders Implemented
                  </li>
                  <li>On-board Partnerships</li>
                </ul>
              </ContentText>
            </ContentRow>
            <ContentRow>
              <ContentTitle data-aos="flip-left" data-aos-delay="600">
                2024
                <Circle />
              </ContentTitle>
              <ContentText data-aos="fade-up" data-aos-delay="650">
                Community Driven Growth Expansion
              </ContentText>
            </ContentRow>
          </ContentWrapper>
        </Container>
      </SectionWrapper>
    </Scroll.Element>
  );
}

export default Section4;
