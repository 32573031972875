import styled from "styled-components/macro";
import { ContentWrapper, ListContent, ListTitle, ThanksTitle, StyledContainer, SubTitle, TitleWrapper } from "./Styled";

const SectionWrapper = styled.section`
  background-color: #161f2f;
  display: flex;
  position: relative;
  min-height: 80vh;
  padding: 7rem 0 3rem;

  @media (min-width: 576px) {
    padding-top: 5rem;
  }

  @media (min-width: 992px) {
    padding-bottom: 10rem;
  }

  @media (min-width: 1200px) {
    padding: 3rem 0 7rem;
  }

  @media (min-width: 1920px) {
    padding: 3rem 0 8rem;
  }
`;

function Thanks(): JSX.Element {
  return (
    <SectionWrapper>
      <StyledContainer>
        <TitleWrapper className="col">
          <ThanksTitle>Thank You!</ThanksTitle>
          <SubTitle>
            We have captured your entry, stay tuned for an update soon!
          </SubTitle>
        </TitleWrapper>
        <ContentWrapper className="col">
          <ListTitle>Whitelisting Utility:</ListTitle>
          <ListContent>
            <li>Community Discord Server</li>
            <li>Exclusive Access To Gen 1 Series</li>
            <li>VIP Privileges On Marketplace</li>
            <li>First-To-Know Notifications on CasperPunk Announcements</li>
            <li>Player Access to CasperPunk Games When Released</li>
            <li>Being Part Of History</li>
          </ListContent>
        </ContentWrapper>
      </StyledContainer>
    </SectionWrapper>
  );
}

export default Thanks;
