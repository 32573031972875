import { useState } from "react";
import { Redirect } from "react-router-dom";
import CustomForm from "pages/CustomForm";
import {
  SectionWrapper,
  ContentWrapper,
  ListContent,
  ListTitle,
  FormWrapper,
  TitleWrapBg,
  StyledContainer,
  StyledTitle,
} from "./Styled";
import BlankPunk from "assets/images/blankpunk.png";
import ContentImg from "assets/images/signup.png";

function SignUp(): JSX.Element {
  const [showSecondTitle, setShowSecondTitle] = useState(false);

  if (showSecondTitle) return <Redirect to="/thanks" />;

  return (
    <>
      <SectionWrapper>
        <StyledContainer>
          <FormWrapper className="col-lg-7" secondTitle={showSecondTitle}>
            {showSecondTitle ? (
              <>
                <StyledTitle>
                  Whitelist
                  <br />
                  coming soon
                </StyledTitle>
              </>
            ) : (
              <>
                <StyledTitle>
                  Join the
                  <br />
                  whitelist!
                </StyledTitle>
                <CustomForm showSecondTitle={setShowSecondTitle} />
              </>
            )}
            <TitleWrapBg src={BlankPunk} />
          </FormWrapper>
          <ContentWrapper className="col-lg-5">
            <ListTitle>Whitelisting Utility:</ListTitle>
            <ListContent>
              <li>Community Discord Server</li>
              <li>Exclusive Access To Gen 1 Series</li>
              <li>VIP Privileges On Marketplace</li>
              <li>First-To-Know Notifications on CasperPunk Announcements</li>
              <li>Player Access to CasperPunk Games When Released</li>
              <li>Being Part Of History</li>
            </ListContent>
          </ContentWrapper>
        </StyledContainer>
      </SectionWrapper>
      <img style={{ width: "100%" }} src={ContentImg} alt="" />
    </>
  );
}

export default SignUp;
