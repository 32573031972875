import { useEffect } from "react";
import AOS from "aos";
import { Container } from "react-bootstrap";
import {
  SectionWrapper,
  SubTitle,
  StyledLink,
  ContentText,
  ContentWrapper,
  ContentBox,
  ContentInner,
} from "./Styled";
import { ThinSectionTitle } from "components/SectionTitle";
import { ButtonRed } from "components/Button";
import Punk from "assets/images/punk.png";

function MintingExperience(): JSX.Element {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <SectionWrapper>
        <Container>
          <div className="col col-md-9 col-lg-8 col-xl-7 col-xxl-6">
            <SubTitle data-aos="fade-down" data-aos-delay="300">
              CasperPunks Gen1
            </SubTitle>
            <ThinSectionTitle data-aos="fade-right" data-aos-delay="400">
              <span>Minting Experience</span>
            </ThinSectionTitle>
            <div className="mt-5 mb-5">
              <ButtonRed data-aos="fade-right" data-aos-delay="500">
                <StyledLink
                  to="/purchase"
                >
                  Gen1 Presale Guide
                </StyledLink>
              </ButtonRed>
            </div>
            <ContentText data-aos="fade-up" data-aos-delay="600">
              First, we would like to congratulate all the whitelisted
              participants. This minting event will be a historical moment for
              us and the Casper community in general. And while we want to make
              sure that the event happens as smoothly as possible, we expect a
              spike in transaction volume on the Casper Network during the
              mining event. To prevent any network issues, we will be adopting
              an unique minting method that involves mystery boxes and raffle
              draws.
            </ContentText>
          </div>
        </Container>
      </SectionWrapper>
      <ContentWrapper>
        <ContentInner>
          <img
            className="col col-sm-12 col-lg-5 col-xl-5"
            src={Punk}
            data-aos="flip-up"
            data-aos-delay="650"
          />
          <div className="col col-sm-12 col-lg-7 col-xl-7">
            <ContentBox>
              <ContentText dark data-aos="fade-left" data-aos-delay="700">
                CasperPunks are unique Digital Collectibles created on the
                Casper Network using one of the world's most advanced
                dynamic-NFT standards. There will only ever be 10,000
                CasperPunks in existence from the Generation 1 series and only
                100 super-rare from the 2021 Generation 0 series.
              </ContentText>
            </ContentBox>
            <ContentBox bgRed>
              <ContentText data-aos="fade-left" data-aos-delay="800">
                While we have received over 10,000 whitelist spots to mint a
                Gen1 Casperpunk, we will only be selling 5,000 during the
                minting event as the remaining 5,000 Casperpunks are to be
                discovered through breeding and evolving. As spaces are limited,
                we recommend our community to be prepared early for the mint.
              </ContentText>
            </ContentBox>
          </div>
        </ContentInner>
      </ContentWrapper>
    </>
  );
}

export default MintingExperience;
