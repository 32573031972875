import styled from "styled-components/macro";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom"

export const MainMenu = styled.div`
  position: relative;
  flex: 1;
`;

export const NavBarWrap = styled.div<{ footer?: boolean }>`
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    justify-content: ${({ footer }) => ( footer ? 'center' : 'flex-start' )};
    width: 100%;
    margin-bottom: 0;
    
    @media (min-width: 768px) {
      justify-content: ${({ footer }) => ( footer ? 'flex-end' : 'center' )};
    }

    li {
      margin: 0 10px;
      
      @media (min-width: 1200px) {
        margin: 0 1rem;
      }

      @media (min-width: 1400px) {
        margin: 0 1.25rem;
      }
    }
  }
`;

export const NavLink = styled(RouterLink)<{ darkMode?: boolean }>`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ darkMode }) => ( darkMode ? '#fff' : '#000' )};

  &:hover {
    color: ${({ darkMode }) => ( darkMode ? '#fff' : '#000' )};
    text-shadow: ${({ darkMode }) => ( darkMode ? '0 0 5px #fff' : '0 0 2px #000' )};
    transition: all 0.5s ease;
    cursor: pointer;
  }
`;

export const StyledNavLink = styled(Link)<{ darkMode?: boolean }>`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ darkMode }) => ( darkMode ? '#fff' : '#000' )};

  &:hover {
    color: ${({ darkMode }) => ( darkMode ? '#fff' : '#000' )};
    text-shadow: ${({ darkMode }) => ( darkMode ? '0 0 5px #fff' : '0 0 2px #000' )};
    transition: all 0.5s ease;
    cursor: pointer;
  }
`;

export const StyledLink = styled.a<{ darkMode?: boolean }>`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ darkMode }) => ( darkMode ? '#fff' : '#000' )};

  &:hover {
    color: ${({ darkMode }) => ( darkMode ? '#fff' : '#000' )};
    text-shadow: 0 0 1px ${({ darkMode }) => ( darkMode ? '#fff' : '#000' )};
    transition: all 0.5s ease;
    cursor: pointer;
  }

`

export const MobileMenu = styled.ul`
  list-style: none;
`;
