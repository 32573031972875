import { useState } from "react";
import { Container } from "react-bootstrap";
import {
  HeaderMain,
  HeaderWrapper,
  Logo,
  LogoWrap,
  MenuToggle,
} from "./Styled";

import Navigation from "../Navigation";
import LogoLight from "assets/images/logo-light.svg";
import { FiMenu } from "react-icons/fi";
import { useAccount } from "state/wallet/hooks";
import ConnectButton from "../ConnectButton"
import ConnectModal from "components/ConnectButton/ConnectModal";

function Header(): JSX.Element {
  const account = useAccount();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  return (
    <HeaderMain>
      <Container>
        <HeaderWrapper>
          <LogoWrap href="/">
            <Logo src={LogoLight} />
          </LogoWrap>
          <Navigation
            isOpen={mobileMenuOpen}
            onHide={() => setMobileMenuOpen(false)}
          />
          <MenuToggle onClick={handleMobileMenuOpen}>
            <FiMenu size={24} />
          </MenuToggle>
          {/*{account ? (*/}
          {/*  <ConnectButton*/}
          {/*    onClick={() => setShowConnectModal(true)}*/}
          {/*  >*/}
          {/*    {account}*/}
          {/*  </ConnectButton>*/}
          {/*) : (*/}
          {/*  <ConnectButton*/}
          {/*    onClick={() => setShowConnectModal(true)}*/}
          {/*  >*/}
          {/*    Connect Wallet*/}
          {/*  </ConnectButton>*/}
          {/*)}*/}

          <ConnectButton />
          <ConnectModal
            show={showConnectModal}
            onHide={() => setShowConnectModal(false)}
          />
        </HeaderWrapper>
      </Container>
    </HeaderMain>
  );
}

export default Header;
