import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Scroll from "react-scroll";
import Section1 from "pages/Section1";
import Section2 from "pages/Section2";
import Section3 from "pages/Section3";
import Section4 from "pages/Section4";
import LandingPage from "pages/LandingPage";

// import Section5 from "pages/Section5";

function HomePage(): JSX.Element {
  const location = useLocation();
  const scroller = Scroll.scroller;

  useEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash.substring(1), {
        duration: 700,
        smooth: true,
      });
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <LandingPage />
      <Section1 />
      <Section2 />
      {/*<Section3 />*/}
      <Section4 />
      {/* <Section5 /> */}
    </>
  );
}

export default HomePage;
