import { useEffect, useState } from "react";
import axios from "axios";
import InputField from "components/InputField";
import {
  StyledForm,
  FieldContainer,
  ButtonWrapper,
  StyledButton,
  StyledButtonSecond,
  Spinner,
  RequiredWrapper,
  CheckBoxWrapper,
  StyledLink,
  ErrorMessage,
} from "./Styled";
import WalletDark from "assets/images/wallet-dark.svg";

interface ICustomFormProps {
  showSecondTitle?: any;
}

function CustomForm(props: ICustomFormProps): JSX.Element {
  const { showSecondTitle } = props;

  const [email, setEmail] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [discord, setDiscord] = useState("");
  const [twitter, setTwitter] = useState("");
  const [firstChecked, setFirstChecked] = useState<boolean>();
  const [secondChecked, setSecondChecked] = useState<boolean>();

  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (email !== "" && walletAddress !== "" && firstChecked && secondChecked) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, walletAddress, firstChecked, secondChecked]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (email && walletAddress && email.indexOf("@") > -1) {
      await axios
        .post("https://sheet.casperpunks.io/whitelist/add", {
          wallet: walletAddress,
          email,
          discord,
          twitter,
        })
        .then(function (response) {
          if (response.status === 200) {
            setLoading(false);
            showSecondTitle(true);
          }
        })
        .catch(function (error) {
          setLoading(false);
          setError(error.response.data.errors);
        });
    }
  };

  return (
    <StyledForm onSubmit={(e) => handleSubmit(e)}>
      <FieldContainer>
        <InputField
          pattern="^0(1[0-9a-fA-F]{64}|2[0-9a-fA-F]{66})$"
          onChangeHandler={(e) => {
            setWalletAddress(e);
            setError("");
          }}
          type="text"
          value={walletAddress}
          placeholder="Casper wallet address*"
          isRequired
          hint={true}
        />
        <InputField
          pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
          onChangeHandler={(e) => {
            setEmail(e);
            setError("");
          }}
          type="email"
          value={email}
          placeholder="Email address*"
          isRequired
        />
        <InputField
          onChangeHandler={(e) => setDiscord(e)}
          type="text"
          value={discord}
          placeholder="Discord"
        />
        <InputField
          onChangeHandler={(e) => setTwitter(e)}
          type="text"
          value={twitter}
          placeholder="Twitter"
        />
      </FieldContainer>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <RequiredWrapper>
        <p>*Required</p>
        <CheckBoxWrapper>
          <input
            type="checkbox"
            onChange={(e) => setFirstChecked(e.target.checked)}
          />
          <span>
            I have read and agree to the <a href="/termofuse">TOU</a>
          </span>
        </CheckBoxWrapper>
        <CheckBoxWrapper>
          <input
            type="checkbox"
            onChange={(e) => setSecondChecked(e.target.checked)}
          />
          <span>
            I have read and agree to be contacted and recieve marketing
            communication as per the <a href="/termofuse">TOU</a>
          </span>
        </CheckBoxWrapper>
      </RequiredWrapper>

      <ButtonWrapper>
        <StyledButton disabled={disabled}>
          {loading && <Spinner />}
          Join now!
        </StyledButton>
        <StyledButtonSecond>
          <StyledLink href="https://cspr.live/sign-in" target="_blank">
            <img src={WalletDark} alt="" />
            Need a Casper wallet?
          </StyledLink>
        </StyledButtonSecond>
      </ButtonWrapper>
    </StyledForm>
  );
}

export default CustomForm;
