import styled from "styled-components/macro";
import BgImage from "assets/images/landing-background.png";
import { Link } from "react-router-dom";
import { ButtonYellow } from "components/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Container } from "react-bootstrap";

export const SectionWrapper = styled.section`
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 10rem 0;

  @media (min-width: 768px) {
    padding: 15rem 0;
  }
`;

export const StyledContainer = styled(Container)`
  @media (min-width: 992px) {
    display: block;
  }
`;

export const SubTitle = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
`;

export const StatusText = styled.p<{ color?: "fail" | "success" }>`
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: ${({ color }) =>
    color === "fail" ? "#ef0c3c" : color === "success" ? "#11ef0c" : "#ecb049"};
  margin-top: 4rem;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 35px;
  }

  @media (min-width: 1200px) {
    font-size: 36px;
    line-height: 42px;
  }
`;

export const ButtonWrap = styled.div`
  display: inline-grid;
  margin-top: 5rem;

  @media (min-width: 576px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const ConnectButton = styled(ButtonYellow)`
  margin-bottom: 1.5rem;

  @media (min-width: 576px) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
`;

export const StyledLink = styled.a`
  color: #fff;

  &:hover {
    color: #fff;
  }
`;

export const StyledProgressBar = styled(ProgressBar)`
  &.progress {
    background-color: transparent !important;
    border: 1px solid #ecb049;
    border-radius: 0;
  }
`;
