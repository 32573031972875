import styled from "styled-components/macro";

export const InputLabel = styled.label`
  position: relative;
  display: grid;
  grid-row-gap: 10px;
  color: #fff;
  font-size: 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
`;

export const StyledInputField = styled.input`
  background: #FFFFFF;
  border: 1px solid #909090;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  padding: 0.5rem 1rem;
  margin: 0 auto;
  width: 80%;
  color: #000;
`;

export const InputArea = styled.textarea`
  background-color: #cacaca;
  border: none;
  font-size: 16px;
  padding: 16px 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  font-weight: bold;
  color: #000;
`;

export const HintLabel = styled.img`
  position: absolute;
  right: 0rem;
  top: 50%;
  transform: translateY(-50%);
  
  @media (min-width: 576px) {
    right: 0.5rem;
  }
`
