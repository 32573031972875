import { Offcanvas } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import { StyledLink, MainMenu, MobileMenu, NavBarWrap, NavLink, StyledNavLink } from "./Styled";

interface INavigationProps {
  darkMode?: boolean;
  isOpen?: boolean;
  onHide?: any;
  footer?: boolean;
}

function Navigation(props: INavigationProps): JSX.Element {
  const { darkMode, isOpen, onHide, footer } = props;
  const location = useLocation();

  return (
    <MainMenu>
      {footer ? (
        <NavBarWrap className="d-lg-flex flex-grow-1" footer={footer}>
          <ul className="main-menu list-unstyled">
            <li>
              <NavLink to="/termofuse" darkMode={darkMode}>Term Of Use</NavLink>
            </li>
            <li>
              <NavLink to="/policy" darkMode={darkMode}>Privacy Policy</NavLink>
            </li>
            <li>
              <NavLink to="/faq" darkMode={darkMode}>FAQ</NavLink>
            </li>
          </ul>
        </NavBarWrap>
      ) : (
        <NavBarWrap className="d-none d-lg-flex flex-grow-1" footer={footer}>
          <ul className="main-menu list-unstyled">
            <li>
              <NavLink to="/whitelist" darkMode={darkMode}>Whitelist</NavLink>
            </li>
            {location.pathname === "/" ? (
              <>
                <li>
                  <StyledNavLink to="minting" spy={true} smooth={true} darkMode={darkMode}>Minting</StyledNavLink>
                </li>
                <li>
                  <StyledLink href="https://gen0cpdc.casperpunks.io/" target="_blank" darkMode={darkMode} onClick={onHide}>
                    Marketplace
                  </StyledLink>
                </li>
              </>
            ) : (
              <>
                <li>
                  <NavLink to="/#minting" darkMode={darkMode}>Minting</NavLink>
                </li>
                <li>
                  <StyledLink href="https://gen0cpdc.casperpunks.io/" target="_blank" darkMode={darkMode} onClick={onHide}>
                    Marketplace
                  </StyledLink>
                </li>
              </>
            )}
            <li>
              <StyledLink href="https://gen0.casperpunks.io/" target="_blank" darkMode={darkMode} onClick={onHide}>
                Gen-0
              </StyledLink>
            </li>
            <li>
              <NavLink to="/faq" darkMode={darkMode}>FAQ</NavLink>
            </li>
            <li>
              <StyledLink href="https://docs.casperpunks.io/" target="_blank" darkMode={darkMode}>
                Docs
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://cspr.live/sign-in" target="_blank" darkMode={darkMode}>
                Create Wallet
              </StyledLink>
            </li>
          </ul>
        </NavBarWrap>
      )}
      <Offcanvas show={isOpen} onHide={onHide}>
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <MobileMenu>
            <li>
              <NavLink to="/whitelist" darkMode={darkMode} onClick={onHide}>Whitelist</NavLink>
            </li>
            {location.pathname === "/" ? (
              <>
                <li>
                  <StyledNavLink to="about" spy={true} smooth={true} darkMode={darkMode} onClick={onHide}>About</StyledNavLink>
                </li>
                <li>
                  <StyledNavLink to="minting" spy={true} smooth={true} darkMode={darkMode} onClick={onHide}>Minting</StyledNavLink>
                </li>
                <li>
                  <StyledNavLink to="roadmap" spy={true} smooth={true} darkMode={darkMode} onClick={onHide}>Roadmap</StyledNavLink>
                </li>
                <li>
                  <StyledLink href="https://gen0cpdc.casperpunks.io/" target="_blank" darkMode={darkMode} onClick={onHide}>
                    Marketplace
                  </StyledLink>
                </li>
              </>
            ) : (
              <>
                <li>
                  <NavLink to="/#minting" darkMode={darkMode} onClick={onHide}>Minting</NavLink>
                </li>
                <li>
                  <StyledLink href="https://gen0cpdc.casperpunks.io/" target="_blank" darkMode={darkMode} onClick={onHide}>
                    Marketplace
                  </StyledLink>
                </li>
              </>
            )}
            <li>
              <StyledLink href="https://gen0.casperpunks.io/" target="_blank" darkMode={darkMode} onClick={onHide}>
                Gen-0
              </StyledLink>
            </li>
            <li>
              <NavLink to="/faq" darkMode={darkMode} onClick={onHide}>FAQ</NavLink>
            </li>
            <li>
              <StyledLink href="https://docs.casperpunks.io/" target="_blank" darkMode={darkMode}>
                Docs
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://cspr.live/sign-in" target="_blank" darkMode={darkMode} onClick={onHide}>
                Create Wallet
              </StyledLink>
            </li>
          </MobileMenu>
        </Offcanvas.Body>
      </Offcanvas>
    </MainMenu>
  );
}

export default Navigation;
