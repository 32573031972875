import { useEffect, useState } from "react";
import { useAccount } from "state/wallet/hooks";
import ConnectModal from "./ConnectModal";
import AccountModal from "components/AccountModal";
import { ButtonConnect } from "components/Button";
import { CiWallet } from "react-icons/ci";

function ConnectButton(): JSX.Element {
  const account = useAccount();

  const [walletAccount, setWalletAccount] = useState<any>();
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);

  useEffect(() => {
    if (account) {
      setWalletAccount(account);
    }
  }, [account]);

  const accountEllipsis = walletAccount
    ? `${walletAccount.substring(0, 6)}...${walletAccount.substring(
        walletAccount.length - 4
      )}`
    : "";

  return (
    <>
      {account ? (
        <>
          {/*
          // @ts-ignore */}
          <ButtonConnect onClick={() => setShowAccountModal(true)}>
            <span>{accountEllipsis}</span>
          </ButtonConnect>
          <AccountModal
            account={account}
            show={showAccountModal}
            onHide={() => setShowAccountModal(false)}
          />
        </>
      ) : (
        <>
          {/*
          // @ts-ignore */}
          <ButtonConnect onClick={() => setShowConnectModal(true)}>
            <span>Connect Wallet</span>
          </ButtonConnect>
          <ConnectModal
            show={showConnectModal}
            onHide={() => setShowConnectModal(false)}
          />
        </>
      )}
    </>
  );
}

export default ConnectButton;
