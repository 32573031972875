import { ConnectorNames } from "connectors";

export const NetworkContextName = "NETWORK";
export const ConnectorLocalStorageKey = "connectorId";

export const NATIVE_TOKEN_ADDERSS =
  "0x1111111111111111111111111111111111111111";
export const SUPPORTED_NFT_COLLECTIONS_URL_EVM =
  "https://raw.githubusercontent.com/dotoracle/evm-contract-lists/main";
export const SUPPORTED_NFT_COLLECTIONS_URL_CASPER =
  "https://raw.githubusercontent.com/dotoracle/casper-contract-hash/master/nftconfig.json";
const now = Date.now() / 1000;
export const R2_START_TIME = 1681318800;
export const R3_START_TIME = 1681405200;
export const R3_END_TIME = R3_START_TIME + 60 * 60 * 24;
// test
// export const R2_START_TIME = 1681300925;
// export const R3_START_TIME = 1681301105;
// export const R3_END_TIME = R3_START_TIME + 60 * 20;

export const BOX_PRICE = now < R3_START_TIME ? 3000 : 4000;
export const TOTAL_BOX = now < R3_START_TIME ? 1000 : 2500;
export const MAXIMUM_BUY = now < R3_START_TIME ? 10 : 25;
export const ROUND = now < R3_START_TIME ? 2 : 3;

export interface WalletInfo {
  connectorId: string;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  CASPERWALLET: {
    connectorId: ConnectorNames.CasperWallet,
    name: "Casper Wallet",
    iconName: "casper.png",
    description: "",
    href: null,
    color: "#E8831D",
  },
  CASPERSIGNER: {
    connectorId: ConnectorNames.CasperSigner,
    name: "Casper Signer",
    iconName: "casper.png",
    description: "",
    href: null,
    color: "#E8831D",
  },
  CASPERDASH: {
    connectorId: ConnectorNames.CasperDash,
    name: "Casper Dash",
    iconName: "casper.png",
    description: "",
    href: null,
    color: "#E8831D",
  },
  TOTUSWALLET: {
    connectorId: ConnectorNames.TorusWallet,
    name: "Torus Wallet",
    iconName: "torus.png",
    description: "",
    href: null,
    color: "#E8831D",
  },
};
