import { useCallback, useEffect, useState } from "react";
import AOS from "aos";
import {
  SectionWrapper,
  SubTitle,
  ContentWrapper,
  TitleContainer,
  ContentContainer,
  LogoWrap,
  InfoRow,
  StepButton,
  ClaimButton,
  RequiredText,
  BoxCount,
  StatusText,
} from "./Styled";
import { BoldSectionTitle, ThinSectionTitle } from "components/SectionTitle";
import LootBox from "assets/images/loot-box.png";
import Countdown, { zeroPad } from "react-countdown";
import {
  useAccount,
  useConnectorId,
  useWalletProvider,
} from "state/wallet/hooks";
import { SafeEventEmitterProvider } from "casper-js-sdk/dist/services/ProviderTransport";
import {
  CasperClient,
  CLPublicKey,
  DeployUtil,
  CasperServiceByJsonRPC,
  CLByteArray,
  CLValueBuilder,
  RuntimeArgs,
} from "casper-js-sdk";
import { useActiveWeb3React, useCurrentNetwork, useGetMintedBox } from "hooks";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import Loader from "components/Loader";
import {
  BOX_PRICE,
  TOTAL_BOX,
  R2_START_TIME,
  R3_START_TIME,
  R3_END_TIME,
  MAXIMUM_BUY,
  ROUND,
} from "../../constants";
import { numberWithCommas } from "utils";
import ConnectModal from "components/ConnectButton/ConnectModal";
import { ConnectorNames } from "connectors";
import { createRecipientAddress } from "casper-js-client-helper/dist/helpers/lib";
import { sha256 } from "js-sha256";
import BigNumber from "bignumber.js";
import TransactionConfirmationModal from "components/TransactionConfirmationModal";

function PresalePurchase(): JSX.Element {
  const account = useAccount();
  const currentNetwork = useCurrentNetwork();
  const connectorId = useConnectorId();
  const provider = useWalletProvider();
  const { connector } = useActiveWeb3React();

  const { data: mintedBox, error: srwError } = useGetMintedBox();
  const [isWhitelisted, setWhitelisted] = useState<boolean | null>();
  const [boxAmount, setBoxAmount] = useState<any>(1);
  const [totalPrice, setTotalPrice] = useState<any>(BOX_PRICE * boxAmount);

  const [showConnectModal, setShowConnectModal] = useState(false);
  const [status, setStatus] = useState<"success" | "fail">();
  const [loading, setLoading] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);
  const [attemptingTxn, setAttemptingTxn] = useState(false);
  const [txHash, setTxHash] = useState("");

  const now = Date.now() / 1000;
  const endDate =
    R2_START_TIME < now && now < R3_START_TIME || now < R2_START_TIME
      ? R2_START_TIME + 60 * 60 * 24
      : R3_END_TIME;
  const countDownTime = R2_START_TIME;
  // R2_START_TIME < now && now < R3_START_TIME ? R3_START_TIME : R2_START_TIME;

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const onCheckingEligibility = async () => {
    try {
      const result = await axios.get(
        `https://api-gen0.casperpunks.io/lootbox/checkWhitelist/${account}`
      );

      if (result) {
        if (result.data.ok === true) {
          setWhitelisted(true);
        } else if (result.data.ok === false) {
          setWhitelisted(false);
        } else {
          setWhitelisted(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (account) {
      onCheckingEligibility();
      // setTimeout(onCheckingEligibility, 5000);
    }
  }, [account]);

  const stepUp = () => {
    const amount = boxAmount + 1 > MAXIMUM_BUY ? MAXIMUM_BUY : boxAmount + 1;
    const _total = amount * BOX_PRICE;

    setBoxAmount(amount > MAXIMUM_BUY ? MAXIMUM_BUY : amount);
    setTotalPrice(_total);
  };

  const stepDown = () => {
    const amount = boxAmount - 1;
    const _total = amount * BOX_PRICE;

    setBoxAmount(amount);
    setTotalPrice(_total);
  };

  useEffect(() => {
    if (boxAmount <= 0) {
      setBoxAmount(1);
    }
  }, [boxAmount]);

  const onClaim = async () => {
    try {
      setLoading(true);
      setShowConfirm(true);
      setAttemptingTxn(true);
      let gasFee = 0;

      if (account && currentNetwork) {
        const _response = await axios.get(
          `${currentNetwork.boxApi}/getboxsalefee?factoryContract=${currentNetwork.contract.factoryHash}&numberOfBox=${boxAmount}`
        );
        if (_response.status === 200) {
          gasFee = _response.data.gasFee;
        }

        const totalAmount = new BigNumber(totalPrice)
          .multipliedBy(1e9)
          .toString();
        const senderKey = CLPublicKey.fromHex(account);
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          currentNetwork?.key ?? "casper-test",
          1,
          1800000
        );

        let boxHash = currentNetwork.contract.BoxPackageHash;
        boxHash = new CLByteArray(Uint8Array.from(Buffer.from(boxHash, "hex")));
        let boxKey = createRecipientAddress(boxHash);

        let factoryHash = currentNetwork.contract.factoryHash;
        factoryHash = new CLByteArray(
          Uint8Array.from(Buffer.from(factoryHash, "hex"))
        );
        let factoryKey = createRecipientAddress(factoryHash);

        const meta_data_json = {
          name: "Casperpunks Mystery Box",
          symbol: "MBOX",
          token_uri: "https://api-gen0.casperpunks.io/lootbox.png",
          checksum: sha256("https://api-gen0.casperpunks.io/lootbox.png"),
        };
        let token_metadata = CLValueBuilder.string(
          JSON.stringify(meta_data_json)
        );

        const runtimeArgs = RuntimeArgs.fromMap({
          deposit_entry_point_name: CLValueBuilder.string("mint"),
          amount: CLValueBuilder.u512(totalAmount),
          factory_contract_hash: factoryKey,
          nft_contract_package: boxKey,
          count: CLValueBuilder.u8(boxAmount),
          token_metadata: token_metadata,
          token_owner: createRecipientAddress(CLPublicKey.fromHex(account)),
        });

        const response = await axios.get("/payment_contract.wasm", {
          responseType: "arraybuffer",
        });

        const instance = new Uint8Array(Buffer.from(response.data, "binary"));
        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newModuleBytes(instance, runtimeArgs),
          DeployUtil.standardPayment(gasFee)
        );

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy);
          const casperClient = new CasperClient(currentNetwork.rpcURL);

          let signature: any = undefined;
          let deployObject: any = undefined;

          if (connectorId === "caspersigner" || connectorId === "casperdash") {
            signature = await provider.sign(json, account, account);
            const _deploy = DeployUtil.deployFromJson(signature);
            deployObject = _deploy.val;
          } else {
            signature = await provider.sign(JSON.stringify(json), account);
            deployObject = DeployUtil.setSignature(
              deploy,
              signature.signature,
              CLPublicKey.fromHex(account)
            );
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              setTxHash(hash);
              setAttemptingTxn(false);
              setLoading(false);
              setStatus("success");

              toast.success(
                `Claim ${boxAmount} Mystery ${
                  boxAmount > 1 ? "Boxes" : "Box"
                }!`,
                {
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );
            })
            .catch((error: any) => {
              console.error(error);
              toast.error(error);
              setShowConfirm(false);
              setAttemptingTxn(false);
              setStatus("fail");
              setLoading(false);
            });
        }
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setShowConfirm(false);
      setAttemptingTxn(false);
    }
  };

  const onTransfer = async () => {
    try {
      if (account && currentNetwork) {
        const gasFee = 0.1 * 1e9;
        const transferAmount = totalPrice * 1e9;
        const senderKey = CLPublicKey.fromHex(account);
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          currentNetwork?.key ?? "casper-test",
          1,
          1800000
        );

        const casperClient = new CasperClient(currentNetwork.rpcURL);
        const transferDeploy = casperClient.makeTransferDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newTransfer(
            transferAmount,
            CLPublicKey.fromHex(currentNetwork.transferAddress),
            null,
            1
          ),
          DeployUtil.standardPayment(gasFee)
        );

        if (transferDeploy && provider) {
          const json = DeployUtil.deployToJson(transferDeploy);

          let signature: any = undefined;
          let deployObject: any = undefined;
          let deployFn = undefined;

          if (
            connectorId === ConnectorNames.CasperSigner ||
            connectorId === ConnectorNames.CasperDash
          ) {
            signature = await provider.sign(
              json,
              account,
              currentNetwork.transferAddress
            );
            const _deploy = DeployUtil.deployFromJson(signature);

            if (_deploy.val instanceof DeployUtil.Deploy) {
              deployFn = casperClient.putDeploy(_deploy.val);
            }
          } else if (connectorId === ConnectorNames.CasperWallet) {
            signature = await provider.sign(
              JSON.stringify(json),
              account,
              currentNetwork.transferAddress
            );
            deployObject = DeployUtil.setSignature(
              transferDeploy,
              signature.signature,
              CLPublicKey.fromHex(account)
            );
            deployFn = casperClient.putDeploy(deployObject);
          } else {
            // Torus
            // @ts-ignore
            const { torus } = connector;
            console.log(torus?.provider);
            const casperService = new CasperServiceByJsonRPC(
              torus?.provider as SafeEventEmitterProvider
            );
            deployFn = casperService.deploy(transferDeploy);
          }

          if (deployFn) {
            deployFn
              .then(async (hash: any) => {
                console.log(hash);
                setLoading(false);
                setStatus("success");

                toast.success(
                  `Claim ${boxAmount} Mystery ${
                    boxAmount > 1 ? "Boxes" : "Box"
                  }!`,
                  {
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
              })
              .catch((error: any) => {
                console.error(error);
                toast.error(error);

                setStatus("fail");
                setLoading(false);
              });
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    setAttemptingTxn(false);
    setTxHash("");
  }, [txHash]);

  return (
    <SectionWrapper>
      <TitleContainer>
        <div className="col">
          <ThinSectionTitle data-aos="fade-right" data-aos-delay="300">
            <span>
              Claim your Gen1
              <br />
              {now <= R3_END_TIME && `Presale Round ${ROUND}`}
            </span>
          </ThinSectionTitle>
          <BoldSectionTitle data-aos="fade-right" data-aos-delay="350">
            Mystery Box
          </BoldSectionTitle>
        </div>
        {now <= R3_END_TIME && (
          <div className="col" data-aos="fade-left" data-aos-delay="600">
            <SubTitle>Maximum {MAXIMUM_BUY} Mystery Boxes per wallet.</SubTitle>
            <SubTitle>
              Price: <span>{numberWithCommas(BOX_PRICE)}</span> CSPR
            </SubTitle>
            <SubTitle>
              Round is live until {format(endDate * 1000, "MMMM do HH:mm")}
            </SubTitle>
          </div>
        )}
      </TitleContainer>

      <ContentWrapper>
        <ContentContainer>
          <LogoWrap className="col">
            <img src={LootBox} alt="" />
          </LogoWrap>

          {status === "success" ? (
            <div className="col text-center">
              <StatusText status="success">Congratulations!</StatusText>
              <SubTitle>
                Your minting transaction has been successfully submitted to the
                Casper blockchain, please wait for the transaction result.
              </SubTitle>
            </div>
          ) : status === "fail" ? (
            <div className="col text-center">
              <StatusText status="fail">
                Sorry, unfortunately you have not been successful.
              </StatusText>
            </div>
          ) : (
            <div className="col text-center">
              <InfoRow>
                Boxes for Sale :{" "}
                <span>
                  {!srwError && (!mintedBox ? 0 : mintedBox) + "/"}
                  {numberWithCommas(TOTAL_BOX)}
                </span>
              </InfoRow>
              <InfoRow>
                Price <span>{numberWithCommas(BOX_PRICE)} CSPR</span>
              </InfoRow>
              <InfoRow>
                Quantity
                <div>
                  <StepButton onClick={() => stepDown()}>-</StepButton>
                  <BoxCount
                    type="number"
                    value={boxAmount}
                    min={1}
                    max={6}
                    onChange={(e) => {
                      const _amount =
                        Number(e.target.value) > MAXIMUM_BUY
                          ? MAXIMUM_BUY
                          : e.target.value;
                      setBoxAmount(_amount);
                      setTotalPrice(Number(_amount) * BOX_PRICE);
                    }}
                  />
                  <StepButton onClick={() => stepUp()}>+</StepButton>
                </div>
                <span>{numberWithCommas(totalPrice)} CSPR</span>
              </InfoRow>

              {mintedBox === numberWithCommas(TOTAL_BOX) ? (
                <ClaimButton disabled>Claim Now!</ClaimButton>
              ) : (
                <>
                  {account ? (
                    <>
                      {/*
                      // @ts-ignore */}
                      <Countdown
                        date={countDownTime * 1000}
                        zeroPadTime={2}
                        renderer={(props2) =>
                          props2.completed ? (
                            <>
                              {isWhitelisted && (
                                <ClaimButton
                                  onClick={() => onClaim()}
                                  disabled={now > R3_END_TIME}
                                >
                                  {loading && <Loader />}{" "}
                                  {now > R3_END_TIME ? "Ended!" : "Claim Now!"}
                                </ClaimButton>
                              )}
                              {isWhitelisted === false && (
                                <ClaimButton disabled>
                                  You are Not Whitelisted
                                </ClaimButton>
                              )}
                            </>
                          ) : (
                            <ClaimButton disabled>
                              Starting in {zeroPad(props2.hours)}h:
                              {zeroPad(props2.minutes)}m:
                              {zeroPad(props2.seconds)}s
                            </ClaimButton>
                          )
                        }
                      />
                    </>
                  ) : (
                    <ClaimButton onClick={() => setShowConnectModal(true)}>
                      Connect Wallet
                    </ClaimButton>
                  )}
                </>
              )}

              <RequiredText>
                By clicking “Claim Now!”, You agree to our{" "}
                <a href="/faq" target="_blank" rel="noreferrer">
                  TOU
                </a>{" "}
                and our{" "}
                <a href="/policy" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                .
              </RequiredText>
            </div>
          )}
        </ContentContainer>
      </ContentWrapper>

      <ConnectModal
        show={showConnectModal}
        onHide={() => setShowConnectModal(false)}
      />
      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Claim Mystery Box"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </SectionWrapper>
  );
}

export default PresalePurchase;
