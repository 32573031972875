import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import GA4React from "ga-4-react";
import {
  createWeb3ReactRoot,
  Web3ReactProvider,
} from "@dotoracle/web3-react-core";
import { Web3Provider } from "@ethersproject/providers";
import { NetworkContextName } from "./constants";
import { Provider } from "react-redux";
import store from "state";
import { ToastContainer } from 'react-toastify'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider, "any");
  library.pollingInterval = 15000;
  return library;
};

const ga4react = new GA4React("UA-45781124-8");

(async () => {
  await ga4react.initialize();

  ReactDOM.render(
    <React.StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Provider store={store}>
            <App />
          </Provider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
      <ToastContainer position="top-right" autoClose={3000} />
    </React.StrictMode>,
    document.getElementById("root")
  );
})();
