import { Container } from "react-bootstrap";
import { SectionTitle } from "components/SectionTitle";
import styled from "styled-components/macro";
import SectionBg from "assets/images/Section1/background.png";

export const SectionWrapper = styled.section`
  background-image: url(${SectionBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  position: relative;
  min-height: 80vh;
  padding: 7rem 0 3rem;

  @media (min-width: 576px) {
    padding-top: 5rem;
  }

  @media (min-width: 992px) {
    padding-bottom: 10rem;
  }

  @media (min-width: 1200px) {
    padding: 3rem 0 7rem;
  }

  @media (min-width: 1920px) {
    padding: 3rem 0 8rem;
  }
`

export const StyledContainer = styled(Container)`

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const TitleWrapper = styled.div`
  text-align: center;
  position: relative;
  margin-bottom: 5rem;

  @media (min-width: 576px) {
    padding: 3rem 0;
    margin-bottom: 5rem;
  }
  
  @media (min-width: 768px) {
    padding: 3rem 0;
  }
  
  @media (min-width: 992px) {
    padding: 3rem 0;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    padding: 6rem 0;
  }

  @media (min-width: 1400px) {
    padding: 9rem 0;
  }
`

export const FormWrapper = styled.div<{ secondTitle?: boolean }>`
  text-align: center;
  position: relative;
  padding: ${({secondTitle}) => secondTitle && '7rem 0' };
  margin-bottom: 5rem;
  
  @media (min-width: 576px) {
    padding: ${({secondTitle}) => secondTitle ? '7rem 0' : '3rem 0' };
    margin-bottom: 10rem;
  }
  
  @media (min-width: 768px) {
    padding: ${({secondTitle}) => secondTitle ? '15rem 0 7rem' : '7rem 0' };
  }
  
  @media (min-width: 992px) {
    padding: ${({secondTitle}) => secondTitle ? '12rem 0 20rem' : '3rem 0' };
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    padding: ${({secondTitle}) => secondTitle ? '15rem 0 25rem' : '6rem 0' };
  }

  @media (min-width: 1400px) {
    padding: ${({secondTitle}) => secondTitle ? '17rem 0 30rem' : '9rem 0' };
  }
`

export const TitleWrapBg = styled.img`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  transform: scale(2.5) translateY(2.5rem);
  z-index: 0;
  
  @media (min-width: 375px) {
    transform: scale(2.25) translateY(2.25rem);
  }

  @media (min-width: 425px) {
    transform: scale(2) translateY(1.5rem);
  }

  @media (min-width: 576px) {
    transform: scale(1.75) translateY(1.5rem);
  }

  @media (min-width: 768px) {
    transform: scale(1.25) translateY(1.25rem);
  }

  @media (min-width: 992px) {
    transform: scale(1.5) translateY(1.25rem);
  }

  @media (min-width: 1200px) {
    transform: scale(1.35) translateY(1.25rem);
  }
`

export const ContentWrapper = styled.div`
  background: #161F2F;
  position: relative;
  border-radius: 12px;
  padding: 2rem 1rem;
  z-index: 1;
  
  @media (min-width: 425px) {
    padding: 2rem;
  }
  
  @media (min-width: 992px) {
    padding: 2rem 1rem;
  }

  @media (min-width: 1200px) {
    padding: 3rem 2rem;
  }
  
  @media (min-width: 1400px) {
    padding: 4rem 3rem;
    margin-left: 2rem;
  }
`

export const StyledTitle = styled(SectionTitle)`
  color: #000;
`

export const ThanksTitle = styled.h2`
  font-weight: 700;
  font-size: 41px;
  line-height: 50px;
  margin-bottom: 1.75rem;
`

export const SubTitle = styled.p`
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #BFBFBF;
  margin: 0 auto;

  @media (min-width: 375px) {
    width: 80%; 
  }

  @media (min-width: 576px) {
    width: 60%; 
  }

  @media (min-width: 768px) {
    width: 40%; 
  }

  @media (min-width: 992px) {
    width: 60%; 
  }

  @media (min-width: 1400px) {
    width: 50%; 
  }
`

export const ListTitle = styled.p`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 1.5rem;
`

export const ListContent = styled.ul`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ECB049;
  list-style-type: disc;
  margin-left: 1.5rem;
  
  @media (min-width: 576px) {
    font-size: 20px;
    line-height: 24px;
    margin-left: 2rem;
  }

  @media (min-width: 1200px) {
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
  }
`
