import styled from "styled-components/macro";

export const SectionWrapper = styled.section`
  background: #161F2F;
  padding-top: 8rem;
  padding-bottom: 5rem;
  
  @media (min-width: 992px) {
    padding-top: 5rem;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 4rem;
`

export const ContentTitle = styled.p`
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #F5F5F5;
  margin-bottom: 1.5rem;
`

export const ContentText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #F5F5F5;
  margin-bottom: 0;

  span {
    font-weight: 700;
  }
  
  a {
    font-weight: 700;
    color: #4592AE;
  }
`

export const ContentList = styled.ul`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #F5F5F5;
  list-style-type: disc;
  margin-left: 1.5rem;
`
