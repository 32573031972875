import Network from "type/Network";
import BigNumber from "bignumber.js";

export const isDev = process.env.REACT_APP_IS_TESTNET === "true";

export const getExplorerLink = (
  network: Network | undefined,
  data: string,
  type: "transaction" | "token" | "address" | "block" | "contract"
): string => {
  const prefix = `${network?.explorer}`;

  if (network === undefined) {
    return "";
  }

  switch (type) {
    case "transaction": {
      return `${prefix}/${network?.txUrl}/${data}`;
    }
    case "token": {
      return `${prefix}/token/${data}`;
    }
    case "block": {
      return `${prefix}/block/${data}`;
    }
    case "contract": {
      return `${prefix}/contract/${data}`;
    }
    case "address":
    default: {
      if (network.key && network.key.includes("casper")) {
        return `${prefix}/account/${data}`;
      }
      return `${prefix}/address/${data}`;
    }
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ipfsURLConvert = (url: string) => {
  return url.replace("ipfs://", "https://ipfs.io/ipfs/");
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const genRanHex = (size = 64) => {
  return [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join("");
};

export const isCacheOutdated = (): boolean => {
  const key = "casperpunk-lastupdated";
  const lastUpdated = localStorage.getItem(key);
  if (lastUpdated !== undefined) {
    const now = Date.now();
    const diff = now - Number(lastUpdated);

    if (diff > 15 * 60 * 1000) {
      localStorage.setItem(key, now.toString());
      return true;
    }

    return false;
  }

  return true;
};

export const trimName = (
  name: string,
  start = 18,
  end = 16,
  total = 40
): string => {
  if (name?.length > total) {
    return `${name.substring(0, start)}...${name.substring(name.length - end)}`;
  } else {
    return name;
  }
};

export function formatWithCommas(value: string): string {
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(value)) {
    value = value.replace(pattern, "$1,$2");
  }
  return value;
}

export const toPrecision = (
  number: string,
  precision: number,
  withCommas = false,
  atLeastOne = true
): string => {
  const [whole, decimal = ""] = number.split(".");

  let str = `${withCommas ? formatWithCommas(whole) : whole}.${decimal.slice(
    0,
    precision
  )}`.replace(/\.$/, "");
  if (atLeastOne && Number(str) === 0 && str.length > 1) {
    const n = str.lastIndexOf("0");
    str = str.slice(0, n) + str.slice(n).replace("0", "1");
  }

  return str;
};

export const toReadableNumber = (decimals: number, number = "0"): string => {
  if (!decimals) return number;
  if (number?.length === 0 || number === null) return "0";
  const wholeStr = number.substring(0, number.length - decimals) || "0";
  const fractionStr = number
    .substring(number.length - decimals)
    .padStart(decimals, "0")
    .substring(0, decimals);

  return `${wholeStr}.${fractionStr}`.replace(/\.?0+$/, "");
};

export const toRoundedReadableNumber = ({
  decimals,
  number = "0",
  precision = 6,
  withCommas = true,
}: {
  decimals: number;
  number?: string;
  precision?: number;
  withCommas?: boolean;
}): string => {
  return toPrecision(toReadableNumber(decimals, number), precision, withCommas);
};

export const formatPrice = (price: number | string): string | number => {
  if (price) {
    return Number.isInteger(Number(price)) ? price : Number(price).toFixed(3);
  } else {
    return 0;
  }
};

export const priceBid = (biddingPrice: string | number): string | number => {
  if (biddingPrice) {
    const _biddingPrice = new BigNumber(biddingPrice).div(1e9).toString();
    return formatPrice(Number(_biddingPrice));
  } else {
    return 0;
  }
};

export const numberWithCommas =(x: number | string): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}