import styled from 'styled-components/macro'
import { useCurrentNetwork } from 'hooks'
import { getExplorerLink } from 'utils'
import ExternalLink from 'components/ExternalLink'

const MainWapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 18rem;
  }
`

const ContentWrapper = styled.div`
  text-align: center;
`

const ContentText = styled.p`
  font-weight: 400;
  font-size: 23px;
  line-height: 28px;
  text-align: justify;
  color: #000;
`

interface TransactionSubmittedContentProps {
  hash: string
}

export function ConfirmationModalContent({
  topContent,
  bottomContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}): JSX.Element {
  return (
    <div>
      <div>{topContent()}</div>
      <div>{bottomContent()}</div>
    </div>
  )
}

function TransactionSubmittedContent(props: TransactionSubmittedContentProps): JSX.Element {
  const { hash } = props
  const networkInfo = useCurrentNetwork()

  return (
    <MainWapper>
      <ContentWrapper>
        <ContentText>
          Your request will be processed when the next block has been written to the Casper Network, this can take up to
          2 minutes. Thank you for your patience.
        </ContentText>
        <ExternalLink href={getExplorerLink(networkInfo, hash, 'transaction')}>View on explorer</ExternalLink>
      </ContentWrapper>
    </MainWapper>
  )
}

// @ts-ignore
export default TransactionSubmittedContent
