import { useEffect, useState } from "react";
import AOS from "aos";
import axios from "axios";
import {
  StatusText,
  SectionWrapper,
  SubTitle,
  ButtonWrap,
  StyledLink,
  ConnectButton,
  StyledProgressBar,
  StyledContainer
} from "./Styled";
import { useAccount } from "state/wallet/hooks";
import { ThinSectionTitle } from "components/SectionTitle";
import { ButtonRed } from "components/Button";
import ConnectModal from "components/ConnectButton/ConnectModal";

function CheckEligibility(): JSX.Element {
  const account = useAccount();
  console.log(account);

  const [checking, setChecking] = useState(false);
  const [status, setStatus] = useState<"success" | "fail">();
  const [showConnectModal, setShowConnectModal] = useState(false);

  const onCheckingEligibility = () => {
    try {
      setChecking(true);

      setTimeout(async () => {
        const result = await axios.get(
          `https://api-gen0.casperpunks.io/lootbox/checkWhitelist/${account}`
        );

        if (result) {
          if (result.data.ok === true) {
            setStatus("success");
          } else if (result.data.ok === false) {
            setStatus("fail");
          } else {
            setStatus(undefined);
          }
        }

        setChecking(false);
      }, 5000);
    } catch (error) {
      console.error(error);
      setChecking(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000
    });

    if (account) {
      onCheckingEligibility();
      // setTimeout(onCheckingEligibility, 5000);
    }
  }, [account]);

  return (
    <SectionWrapper>
      <StyledContainer>
        <div className="col col-md-9 col-lg-8 col-xl-7">
          <SubTitle data-aos="fade-right" data-aos-delay="300">
            Check Eligibility
          </SubTitle>
          <ThinSectionTitle data-aos="fade-right" data-aos-delay="400">
            <span>
              Gen1
              <br />
              CasperPunks
            </span>
          </ThinSectionTitle>
          {status === "success" ? (
            <StatusText color="success">
              Congrats, your wallet is eligible for the presale event, please
              refer to the Minting Experience for further instructions
            </StatusText>
          ) : status === "fail" ? (
            <StatusText color="fail">
              Sorry, unfortunately you have not been successful
            </StatusText>
          ) : (
            <StatusText data-aos="fade-right" data-aos-delay="500">
              {checking
                ? "Checking Eligibility..."
                : "Connect wallet to check eligibility"}
            </StatusText>
          )}

          {checking && (
            <StyledProgressBar animated variant="warning" now={100} />
          )}

          <ButtonWrap>
            {!account && (
              <ConnectButton
                data-aos="fade-up"
                data-aos-delay="600"
                onClick={() => setShowConnectModal(true)}
              >
                Connect Wallet
              </ConnectButton>
            )}

            <ButtonRed data-aos="fade-up" data-aos-delay="700">
              <StyledLink href="/purchase">Minting Experience</StyledLink>
            </ButtonRed>
          </ButtonWrap>
        </div>

        <ConnectModal
          show={showConnectModal}
          onHide={() => setShowConnectModal(false)}
        />
      </StyledContainer>
    </SectionWrapper>
  );
}

export default CheckEligibility;
