import { Modal as BootstrapModal, ModalTitle } from 'react-bootstrap'
import styled from 'styled-components/macro'

const StyledModal = styled(BootstrapModal)`
  .modal-content {
    background: #fff;
  }

  .modal-header {
    justify-content: center;
    align-items: center;
    border-bottom: none;
    padding: 3rem 2.5rem 0;

    .modal-title {
      color: #000;
    }

    .btn-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .modal-body {
    border-radius: 20px;
    padding: 2rem 2.5rem 3rem;
  }

  .modal-footer {
    border-top: 0;
  }
`

interface IModalProps {
  size?: 'sm' | 'lg' | 'xl'
  className?: any
  show: boolean
  hideTitle?: boolean
  title: string | JSX.Element | JSX.Element[]
  children?: any
  button?: JSX.Element | JSX.Element[]
  onHide: () => void
  backdrop?: any
}

function Modal(props: IModalProps): JSX.Element {
  const { size, className, show, children, button, onHide, backdrop, title, hideTitle } = props

  return (
    <StyledModal className={className} autoFocus centered size={size} show={show} onHide={onHide} backdrop={backdrop}>
      <BootstrapModal.Header closeButton>
        <ModalTitle className={hideTitle ? 'screen-reader-text' : ''}>{title}</ModalTitle>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{children}</BootstrapModal.Body>
      {button && <BootstrapModal.Footer>{button}</BootstrapModal.Footer>}
    </StyledModal>
  )
}

export default Modal
