import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { HintLabel, InputArea, InputLabel, StyledInputField } from "./Styled";
import QuestionMark from "assets/images/questionmark.svg";

interface IInputFiledProps {
  type?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  value?: any;
  formValue?: any;
  isRequired?: boolean;
  pattern?: string;
  hint?: boolean;
  onChangeHandler?: (e: any) => void;
}

function InputField(props: IInputFiledProps): JSX.Element {
  const {
    type,
    label,
    placeholder,
    value,
    name,
    formValue,
    isRequired,
    pattern,
    hint,
    onChangeHandler,
  } = props;

  function validateInput(value: any) {
    if (value.indexOf("@") === -1) {
      return true;
    } else {
      return false;
    }
  }

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      A valid CSPR wallet address and an active email address is required to
      whitelist
    </Tooltip>
  );

  if (type === "submit") {
    return (
      <StyledInputField
        type="submit"
        value={label}
        disabled={validateInput(formValue)}
      />
    );
  } else if (type === "textarea") {
    return (
      <InputLabel>
        {props.label}
        <InputArea
          onChange={(e) => onChangeHandler?.(e.target.value)}
          placeholder={placeholder}
          value={value}
          required={isRequired}
          rows={7}
          name={name}
        />
        {hint && (
          <OverlayTrigger
            placement="top"
            overlay={renderTooltip}
          >
            <HintLabel src={QuestionMark} />
          </OverlayTrigger>
        )}
      </InputLabel>
    );
  } else {
    return (
      <InputLabel>
        {label}
        <StyledInputField
          onChange={(e) => onChangeHandler?.(e.target.value)}
          type={type}
          placeholder={placeholder}
          value={value}
          required={isRequired}
          name={name}
          pattern={pattern}
        />
        {hint && (
          <OverlayTrigger
            placement="top"
            overlay={renderTooltip}
          >
            <HintLabel src={QuestionMark} />
          </OverlayTrigger>
        )}
      </InputLabel>
    );
  }
}

export default InputField;
