import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import SectionBg from "assets/images/Section1/background2.png";

export const SectionWrapper = styled.section`
  /* background-image: url(${SectionBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  display: flex;
  position: relative;
  padding-top: 4rem;
  
  @media (min-width: 992px) {
    padding-top: 0;
  }
`

export const ContentWrapper = styled.div`
  background-color: #AD203E;
  padding: 3rem 1rem;

  @media (min-width: 576px) {
    padding: 3rem 2rem;
  }

  @media (min-width: 992px) {
    padding: 5rem 2rem;
  }

  @media (min-width: 1200px) {
    padding: 6rem;
  }

  @media (min-width: 1920px) {
    padding-left: 19.5rem;
  }

  @media (min-width: 2560px) {
    padding-left: 39rem;
  }
`

export const SocialLinks = styled.div`
  background-color: #161F2F;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 3rem 2rem;
  
  @media (min-width: 768px) {
    padding: 4rem 2rem;
  }

  @media (min-width: 1920px) {
    padding: 6rem;
    padding-left: 19.5rem;
  }

  @media (min-width: 2560px) {
    padding-left: 39rem;
  }

  img {
    width: 30px;
    margin: 0 0.75rem;

    @media (min-width: 768px) {
      margin: 0 1rem;
    }

    @media (min-width: 992px) {
      width: auto;
    }

    @media (min-width: 1400px) {
      margin: 0 1.25rem;
    }
  }
`

export const ContentText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  margin-top: 3rem;
  margin-bottom: 0;
  
  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 27px;
  }

  @media (min-width: 1200px) {
    width: 105%;
  }

  @media (min-width: 1400px) {
    width: 90%;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
`

export const MobileImage = styled.img`
  width: 100%;

  @media (min-width: 576px) {
    display: none;
  }
`

export const EmptyWrap = styled.div`
  display: none;
  background-image: url(${SectionBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: 576px) {
    display: block;
  }

  @media (min-width: 1920px) {
    background-position-y: -8rem;
  }

  @media (min-width: 2560px) {
    background-position-y: -20rem;
  }
`

export const StyledNavLink = styled(Link)`
  color: #161F2F;
  padding: 0 1.5rem;
  
  &:hover {
    color: #161F2F;
  }
`

export const StyledLink = styled.a`
  color: #161F2F;
  padding: 0 1.5rem;
  
  &:hover {
    color: #161F2F;
  }
`
