import styled from "styled-components/macro";
import { Container } from "react-bootstrap";

export const SectionWrapper = styled.section`
  background: transparent;
  padding: 6rem 0;
`

export const StyledContainer = styled(Container)`

  @media (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 4rem;

  @media (min-width: 1200px) {
    margin-left: 3rem;
  }

  @media (min-width: 1920px) {
    margin-left: 0;
  }
`

export const ContentWrapper = styled.div`
  @media (min-width: 375px) {
    margin-left: 1rem;
  }

  @media (min-width: 425px) {
    margin-left: .5rem;
  }

  @media (min-width: 576px) {
    margin-left: 0;
  }
`

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #000;
  margin-bottom: 0;
  margin-top: 2rem;

  @media (min-width: 992px) {
    width: 80%;
  }

  @media (min-width: 1200px) {
    width: 70%;
  }

  @media (min-width: 1400px) {
    width: 60%;
  }
`

export const ContentRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  
  &:last-child {
    margin-bottom: 0;
  }

  img {
    margin-right: 1rem;
  }
`

export const ContentText = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  margin-bottom: 0;

  @media (min-width: 1200px) {
    width: 75%;
  }

  @media (min-width: 1400px) {
    width: 65%;
  }
`
