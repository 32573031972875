import styled from "styled-components/macro";
import BgImage from "assets/images/landing-background.png";
import { Link } from "react-router-dom";

export const SectionWrapper = styled.section`
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 10rem 0;
`;

export const SubTitle = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;

  span {
    font-weight: 600;
  }

  @media (min-width: 576px) {
    font-size: 21px;
    line-height: 27px;
  }

  @media (min-width: 768px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const TimeCountdown = styled.p`
  border-top: 1px solid #6f6f6f;
  border-bottom: 1px solid #6f6f6f;
  font-weight: 600;
  font-size: 24px;
  line-height: 54px;

  span {
    font-weight: 300;
  }

  @media (min-width: 576px) {
    font-size: 28px;
    line-height: 54px;
  }

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 72px;
  }
`;

export const ButtonWrap = styled.div`
  display: inline-grid;
  margin-top: 3rem;

  button:first-child {
    margin-bottom: 1.5rem;
  }

  @media (min-width: 576px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5rem;

    button:first-child {
      margin-bottom: 0;
      margin-right: 1.5rem;
    }
  }
`;

export const StyledLink = styled(Link)`
  color: #fff;

  &:hover {
    color: #fff;
  }
`;
