import styled from "styled-components/macro";
import BgImage from "assets/images/landing-background.png";
import { Link } from "react-router-dom";

export const SectionWrapper = styled.section`
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 10rem 0 5rem;
`;

export const SubTitle = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
`;

export const ContentWrapper = styled.div`
  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(173, 32, 62, 1) 50%,
      rgba(173, 32, 62, 1) 100%
    );
  }
`;

export const ContentInner = styled.div`
  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 1920px) {
    max-width: 1320px;
    margin: auto;
  }
`;

export const ContentBox = styled.div<{ bgRed?: boolean }>`
  background: ${({ bgRed }) => (bgRed ? "#ad203e" : "#fff")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0.75rem;

  @media (min-width: 576px) {
    padding: 2rem;
  }

  @media (min-width: 992px) {
    background: none;
  }

  @media (min-width: 1200px) {
    &:last-child {
      padding-top: 4rem;
    }
  }

  @media (min-width: 1400px) {
    &:last-child {
      padding: 4rem;
    }
    padding: 4rem;
  }

  @media (min-width: 1920px) {
    &:last-child {
      padding: 3.25rem 4rem;
    }
    padding: 3.25rem 4rem;
  }
`;

export const ContentText = styled.p<{ dark?: boolean }>`
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: ${({ dark }) => (dark ? "#000" : "#fff")};
  margin-bottom: 0;
`;

export const StyledLink = styled(Link)`
  color: #fff;

  &:hover {
    color: #fff;
  }
`;
