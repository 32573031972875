import styled from "styled-components/macro";

export const SectionTitle = styled.h2`
  position: relative;
  font-family: 'Gamer';
  font-weight: 500;
  font-size: 63px;
  line-height: 28px;
  color: #ECB049;
  text-transform: uppercase;
  z-index: 2;
  
  span {
    color: #fff;
  }

  @media (min-width: 768px) {
    font-size: 80px;
    line-height: 35px;
  }

  @media (min-width: 1920px) {
    font-size: 103px;
    line-height: 44px;
  }
`

export const ThinSectionTitle = styled.h2`
  position: relative;
  font-weight: 300;
  font-size: 39px;
  line-height: 39px;
  color: #ECB049;
  z-index: 2;
  
  span {
    color: #fff;
  }

  @media (min-width: 768px) {
    font-size: 59px;
    line-height: 64px;
  }

  @media (min-width: 1920px) {
    font-size: 65px;
    line-height: 70px;
  }
`

export const BoldSectionTitle = styled.h2<{ mb?: string }>`
  position: relative;
  font-weight: 800;
  font-size: 39px;
  line-height: 39px;
  color: #ECB049;
  margin-bottom: ${({ mb }) => mb && mb};
  z-index: 2;
  
  span {
    color: #fff;
  }

  @media (min-width: 768px) {
    font-size: 59px;
    line-height: 64px;
  }

  @media (min-width: 1920px) {
    font-size: 65px;
    line-height: 70px;
  }
`
