import { useEffect } from "react";
import AOS from "aos";
import Scroll from "react-scroll";
import { ButtonPrimary } from "components/Button";
import {
  ButtonWrapper,
  ContentText,
  ContentWrapper,
  EmptyWrap,
  MobileImage,
  SectionWrapper,
  SocialLinks,
  StyledLink,
} from "./Styled";
import { ThinSectionTitle } from "components/SectionTitle";
import SectionBg from "assets/images/Section1/background2.png";
import IcoTwitter from "assets/images/twitter.svg";
import IcoTelegram from "assets/images/telegram.svg";
import IcoDiscord from "assets/images/discord.svg";
import IcoMedium from "assets/images/medium.svg";
import "aos/dist/aos.css";

function Section1(): JSX.Element {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <Scroll.Element name="whitelist">
      <SectionWrapper>
        <EmptyWrap className="col" />
        <div className="col">
          <ContentWrapper>
            <ThinSectionTitle data-aos="fade-right" data-aos-delay="300">
              <span>Gen0</span>
              <br />
              Marketplace
              <br />
              has Launched!
            </ThinSectionTitle>
            <ContentText data-aos="fade-left" data-aos-delay="400">
              Trade your punks, make a bid,
              <br />
              the Gen0 marketplace is live!
            </ContentText>
            <ButtonWrapper data-aos="fade-up" data-aos-delay="500">
              <ButtonPrimary>
                <StyledLink href="https://gen0cpdc.casperpunks.io/" target="_blank">Check it out</StyledLink>
              </ButtonPrimary>
            </ButtonWrapper>
          </ContentWrapper>

          <MobileImage src={SectionBg} />

          {/*<SocialLinks>*/}
          {/*  <a*/}
          {/*    href="https://twitter.com/casperpunks_"*/}
          {/*    target="blank_"*/}
          {/*    data-aos="fade-up"*/}
          {/*    data-aos-delay="600"*/}
          {/*  >*/}
          {/*    <img src={IcoTwitter} alt="" />*/}
          {/*  </a>*/}
          {/*  <a*/}
          {/*    href="https://t.me/casperpunks"*/}
          {/*    target="blank_"*/}
          {/*    data-aos="fade-up"*/}
          {/*    data-aos-delay="700"*/}
          {/*  >*/}
          {/*    <img src={IcoTelegram} alt="" />*/}
          {/*  </a>*/}
          {/*  <a*/}
          {/*    href="https://discord.gg/casperpunks"*/}
          {/*    target="blank_"*/}
          {/*    data-aos="fade-up"*/}
          {/*    data-aos-delay="800"*/}
          {/*  >*/}
          {/*    <img src={IcoDiscord} alt="" />*/}
          {/*  </a>*/}
          {/*  <a*/}
          {/*    href="https://medium.com/@casperpunks"*/}
          {/*    target="blank_"*/}
          {/*    data-aos="fade-up"*/}
          {/*    data-aos-delay="900"*/}
          {/*  >*/}
          {/*    <img src={IcoMedium} alt="" />*/}
          {/*  </a>*/}
          {/*</SocialLinks>*/}
        </div>
      </SectionWrapper>
    </Scroll.Element>
  );
}

export default Section1;
