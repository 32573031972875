import styled from "styled-components/macro";
import { ButtonRed } from "../Button";

export const HeaderMain = styled.header`
  position: fixed;
  background-color: #fff;
  top: 0;
  width: 100%;
  z-index: 9;

  @media (min-width: 992px) {
    position: relative;
    background-color: transparent;
  }
`;

export const ConnectButton = styled(ButtonRed)`
  margin-bottom: 1.5rem;
  font-size: 16px;

  @media (min-width: 576px) {
    margin-bottom: 0;
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;

  @media (min-width: 992px) {
    height: 96px;
  }

  @media (min-width: 1200px) {
    padding: 0 3rem;
  }
  
  @media (min-width: 1920px) {
    padding: 0;
  }
`;

export const LogoWrap = styled.a`
  /* margin-right: 2rem; */
`;

export const Logo = styled.img`
  width: 100%;
`;

export const MenuToggle = styled.button`
  background: transparent;
  border: none;

  @media (min-width: 992px) {
    display: none;
  }
`;
