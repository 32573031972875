import styled from "styled-components/macro";
import { SUPPORTED_WALLETS } from "../../constants";
import {
  useChainId,
  useConnectorId,
  useDeactivateCallback,
} from "state/wallet/hooks";
import { useCurrentNetwork } from "hooks";
import { getExplorerLink } from "utils";
import Modal from "components/Modal";
import Copy from "./Copy";
import { BiLinkExternal } from "react-icons/bi";
import { ButtonOutline } from "components/Button";
import ExternalLink from "components/ExternalLink";

const InfoWrapper = styled.div`
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding: 2rem;
`;

const WalletName = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
`;

const AccountKey = styled.p`
  color: #000000;
  font-size: 20px;
  line-height: 28px;
  margin-top: 1rem;
`;

const AddressLink = styled(ExternalLink)`
  font-size: 0.825rem;
  color: #565660;
  margin-left: 1rem;
  display: flex;
  align-items: center;

  &:hover {
    color: #181920;
  }

  span + svg {
    display: none !important;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
  }
`;

interface IAccountModalProps {
  account: string;
  show: boolean;
  onHide: () => void;
}

function AccountModal(props: IAccountModalProps): JSX.Element {
  const { account, show, onHide } = props;

  const accountEllipsis = account
    ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
    : "";

  const connectorId = useConnectorId();
  const chainId = useChainId();
  const deactivate = useDeactivateCallback();
  const networkInfo = useCurrentNetwork();

  const formatConnectorName = () => {
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter((k) => SUPPORTED_WALLETS[k].connectorId === connectorId)
      .map((k) => SUPPORTED_WALLETS[k].name)[0];
    return <>{name && <WalletName>{`Connected with ${name}`}</WalletName>}</>;
  };

  const onLogout = async () => {
    // Clear data
    await deactivate();
    onHide();
  };

  return (
    <Modal size="lg" show={show} title="Your Account" onHide={onHide}>
      <InfoWrapper>
        <div className="d-flex justify-content-between align-items-center">
          {formatConnectorName()}
          <div>
            <ButtonOutline disabled={false} onClick={() => onLogout()}>
              <span>Disconnect</span>
            </ButtonOutline>
          </div>
        </div>
        <AccountKey>{account && accountEllipsis}</AccountKey>
        <div className="d-flex justify-content-left align-items-center">
          {account && (
            <Copy toCopy={account}>
              <span style={{ marginLeft: "4px" }}>Copy</span>
            </Copy>
          )}
          {chainId && account && (
            <AddressLink
              href={getExplorerLink(networkInfo, account, "address")}
            >
              <BiLinkExternal size={18} />
              <span style={{ marginLeft: "4px" }}>View on explorer</span>
            </AddressLink>
          )}
        </div>
      </InfoWrapper>
    </Modal>
  );
}

export default AccountModal;
