import { useEffect } from "react";
import AOS from "aos";
import Scroll from "react-scroll";
import { SectionTitle } from "components/SectionTitle";
import {
  ContentRow,
  ContentText,
  ContentWrapper,
  SectionWrapper,
  StyledContainer,
  Subtitle,
  TitleWrapper,
} from "./Styled";
import Gift from "assets/images/Section2/gift.svg";
import Gamepad from "assets/images/Section2/gamepad.svg";
import Chat from "assets/images/Section2/chat.svg";
import Group from "assets/images/Section2/group.svg";
import "aos/dist/aos.css";

function Section2(): JSX.Element {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <Scroll.Element name="about">
      <SectionWrapper>
        <StyledContainer>
          <TitleWrapper className="col">
            <SectionTitle data-aos="fade-up" data-aos-delay="300">
              A completely
              <br />
              generative
              <br />
              series
            </SectionTitle>
            <Subtitle data-aos="fade-right" data-aos-delay="400">
              10,000 Casper Punks in total, each with unique rarity.
            </Subtitle>
          </TitleWrapper>
          <ContentWrapper className="col">
            <ContentRow>
              <img src={Gift} data-aos="flip-up" data-aos-delay="450" alt="" />
              <ContentText data-aos="fade-left" data-aos-delay="500">
                CasperPunks are unique Digital Collectibles created on the
                Casper Network using one of the world's most advanced
                dynamic-NFT standards.
              </ContentText>
            </ContentRow>
            <ContentRow>
              <img src={Gamepad} data-aos="flip-up" data-aos-delay="500" alt="" />
              <ContentText data-aos="fade-left" data-aos-delay="550">
                CasperPunks will be tradable on the CasperPunks marketplace and
                coming soon in 2023 will be breedable, evolvable and will have
                utility within the CasperPunks game and wider Casper ecosystem.
              </ContentText>
            </ContentRow>
            <ContentRow>
              <img src={Chat} data-aos="flip-up" data-aos-delay="550" alt="" />
              <ContentText data-aos="fade-left" data-aos-delay="600">
                CasperPunks owners will obtain special rights for use in
                commercial or creative activities.
              </ContentText>
            </ContentRow>
            <ContentRow>
              <img src={Group} data-aos="flip-up" data-aos-delay="600" alt="" />
              <ContentText data-aos="fade-left" data-aos-delay="650">
                Community and creativity is hugely important to CasperPunks and
                at the heart of being part of helping a thriving Casper
                ecosystem
              </ContentText>
            </ContentRow>
          </ContentWrapper>
        </StyledContainer>
      </SectionWrapper>
    </Scroll.Element>
  );
}

export default Section2;
