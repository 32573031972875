import styled from "styled-components/macro";

export const SectionWrapper = styled.section`
  background: #161F2F;
  position: relative;
  padding-top: 7rem;
  padding-bottom: 2rem;
`

export const TitleWrapper = styled.div`
  @media (min-width: 1200px) {
    margin-left: 3rem;
  }

  @media (min-width: 1920px) {
    margin-left: 0;
  }
`

export const ContentWrapper = styled.div`
  margin-top: 4rem;
`

export const ContentRow = styled.div`
  position: relative;
  padding-bottom: 2rem;
  
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 4rem;

    &:last-child {
      align-items: center;
    }
  }
`

export const ContentTitle = styled.div`
  position: relative;
  background-color: #39697B;
  border-radius: 92px;
  padding-right: 92px;
  padding-left: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  margin-right: 2rem;
  width: fit-content;
  
  @media (min-width: 576px) {
    font-size: 23px;
    line-height: 44px;
  }

  @media (min-width: 768px) {
    padding-right: 0;
    width: 290px;
  }
`

export const Circle = styled.div`
  position: absolute;
  background-color: #ECB049;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const ContentText = styled.p`
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  margin-top: 1.5rem;
  margin-left: 1rem;

  @media (min-width: 768px) {
    margin: 0;
    width: 50%;
  }

  ul {
    list-style: disc;
    margin-left: 1rem;
  }
`
