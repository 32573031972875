import { HTMLProps } from "react";
import { BiLinkExternal } from "react-icons/bi";
import styled from "styled-components/macro";

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #565660;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
    color: #181920;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
  }

  &:active {
    text-decoration: none;
  }
`;

function ExternalLink({
  target = "_blank",
  href,
  rel = "noopener noreferrer",
  children,
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, "as" | "ref" | "onClick"> & {
  href: string;
}): JSX.Element {
  return (
    <StyledLink target={target} rel={rel} href={href} {...rest}>
      <span>{children}</span>
      <BiLinkExternal
        style={{
          marginLeft: "0.5rem",
          display: "inline-block",
          verticalAlign: "middle",
        }}
      />
    </StyledLink>
  );
}

export default ExternalLink;
