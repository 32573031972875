import styled from "styled-components/macro";

export const SectionWrapper = styled.section`
  background: #161F2F;
  padding-top: 8rem;
  padding-bottom: 5rem;
  
  @media (min-width: 992px) {
    padding-top: 5rem;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 3rem;
`

export const MetaTitle = styled.h3`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #F5F5F5;
  margin-top: 3rem;
  margin-bottom: 1rem;
`

export const MetaSubTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #F5F5F5;
`

export const ContentText = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #F5F5F5;
  margin-bottom: 0;

  span {
    font-weight: 700;
    color: #ECB049;
    width: fit-content;
  }
  
  a {
    color: #4592AE;
    text-decoration-line: underline;
  }
`
