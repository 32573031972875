import styled from "styled-components/macro";
import { Container } from "react-bootstrap";
import { ButtonRed } from "components/Button";

export const SectionWrapper = styled.section`
  background: #000;
  position: relative;
  padding-top: 10rem;
`;

export const ContentWrapper = styled.div`
  background: #161f2f;
`;

export const TitleContainer = styled(Container)`
  margin-bottom: 3rem;

  @media (min-width: 992px) {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
  }

  @media (min-width: 1200px) {
    gap: 0;
  }
`;

export const ContentContainer = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  @media (min-width: 1200px) {
    gap: 0;
  }
`;

export const LogoWrap = styled.div`
  text-align: center;

  @media (min-width: 992px) {
    text-align: left;
  }
`;

export const SubTitle = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;

  span {
    font-weight: 600;
  }

  @media (min-width: 576px) {
    font-size: 21px;
    line-height: 27px;
  }

  @media (min-width: 768px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #6f6f6f;
  padding: 0.5rem 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 61px;

  span {
    font-weight: 800;
  }
`;

export const BoxCount = styled.input`
  background-color: transparent;
  border-left: 1px solid #6f6f6f;
  border-right: 1px solid #6f6f6f;
  border-bottom: 0;
  padding: 0.5rem 0;
  margin: 0 1rem;
  width: 100px;
  font-weight: 800;
  font-size: 20px;
  line-height: 61px;
  color: #fff;
  text-align: center;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const StepButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0.5rem 0;
  font-weight: 800;
  font-size: 20px;
  line-height: 61px;
  color: #fff;
  text-align: center;
`

export const ClaimButton = styled(ButtonRed)`
  padding: 1rem 2rem;
  margin: 3rem 0 0 0;

  &:disabled {
    opacity: 0.6;
  }
`;

export const RequiredText = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin: auto;
  margin-top: 3rem;

  a {
    text-decoration: underline;

    &:hover {
      color: #ad203e;
    }
  }

  @media (min-width: 425px) {
    width: 90%;
  }

  @media (min-width: 576px) {
    font-size: 15px;
    line-height: 18px;
  }

  @media (min-width: 768px) {
    width: 60%;
  }

  @media (min-width: 992px) {
    width: 90%;
  }

  @media (min-width: 1200px) {
    width: 80%;
  }

  @media (min-width: 1400px) {
    width: 70%;
  }
`;

export const StatusText = styled.p<{ status: "fail" | "success" }>`
  font-weight: 300;
  font-size: 49px;
  line-height: 54px;
  text-align: center;
  color: ${({ status }) => (status === "fail" ? "#ef0c3c" : "#11ef0c")};

  span {
    font-size: 44px;
    line-height: 49px;
  }
`;
