import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useGA4React } from "ga-4-react";
import Header from "./components/Header";
import Footer from "components/Footer";
import PrivacyPolicy from "pages/PrivacyPolicy";
import HomePage from "pages/HomePage";
import FAQ from "pages/FAQ";
import TermOfUse from "pages/TermOfUse";
import CookiePolicy from "pages/CookiePolicy";
import SignUp from "pages/SignUp";
import Thanks from "pages/SignUp/Thanks";
import CheckEligibility from "pages/CheckEligibility";
import PresalePurchase from "pages/PresalePurchase";
import MintingExperience from "pages/MintingExperience";
import 'react-toastify/dist/ReactToastify.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/style.scss";
import "assets/fonts/stylesheet.css";

function App(): JSX.Element {
  const ga = useGA4React();
  console.log(ga);

  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          
          <Route exact strict path="/policy">
            <PrivacyPolicy />
          </Route>
          <Route exact strict path="/faq">
            <FAQ />
          </Route>
          <Route exact strict path="/termofuse">
            <TermOfUse />
          </Route>
          <Route exact strict path="/cookiepolicy">
            <CookiePolicy />
          </Route>
          <Route exact strict path="/whitelist">
            <SignUp />
          </Route>
          <Route exact strict path="/thanks">
            <Thanks />
          </Route>
          <Route exact strict path="/eligibility">
            <CheckEligibility />
          </Route>
          <Route exact strict path="/purchase">
            <PresalePurchase />
          </Route>
          <Route exact strict path="/minting">
            <MintingExperience />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default App;
