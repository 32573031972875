import styled from "styled-components/macro";

export const SectionWrapper = styled.section`
  background: #161F2F;
  padding-top: 8rem;
  padding-bottom: 5rem;
  
  @media (min-width: 992px) {
    padding-top: 5rem;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 5rem;
`

export const ContentTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #F5F5F5;
  margin-bottom: 2rem;
`

export const ContentSubTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #F5F5F5;
`

export const ContentText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F5F5F5;
`

export const ContentList = styled.ul`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F5F5F5;
  list-style-type: disc;
  margin-left: 1.5rem;
`