import { configureStore } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";
import application from "./application/reducer";
import transactions from "./transactions/reducer";
import wallet from "./wallet/reducer";

const PERSISTED_KEYS: string[] = ["transactions"];

const store = configureStore({
  reducer: {
    application,
    transactions,
    wallet,
  },
  middleware: [save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
