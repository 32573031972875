import styled from "styled-components/macro";

const Base = styled.button`
  position: relative;
  padding: .75rem 1rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  border: none;
  border-radius: 8px;
  z-index: 1;

  &:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 27px;
  }
`

export const ButtonPrimary = styled(Base)`
  background-color: #fff;
  color: #161F2F;
`

export const ButtonYellow = styled(Base)`
  background-color: #ECB049;
  color: #fff;
`

export const ButtonRed = styled(Base)`
  background-color: #AD203E;
  color: #fff;
`

export const ButtonHint = styled(Base)`
  background-color: #39697B;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`

export const StyledButton = styled.button<{ disabled: boolean | undefined }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  color: #161f2f;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  transition: all 0.3s ease;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#fff' : '#161f2f')};
    border: 1px solid ${({ disabled }) => (disabled ? '#e5e5e5' : '#161f2f')};
    color: ${({ disabled }) => (disabled ? '#565660' : '#fff')};

    svg {
      fill: #fff;
    }
  }
`

export const ButtonOutline = styled(StyledButton)<{ padding?: string }>`
  background: transparent;
  color: #ad203e;
  padding: ${({ padding }) => padding ?? '0.5rem 3rem'};
  border: 1px solid #ad203e;
  font-weight: 800;
  font-size: 17px;
  line-height: 27px;
  text-transform: capitalize;
  transition: all 0.3s ease;

  &:hover {
    background: #ad203e;
    border-color: #ad203e;
  }
`

export const ButtonConnect = styled(StyledButton)<{ padding?: string }>`
  background: #ad203e;
  border: none;
  padding: ${({ padding }) => padding ?? '0.5rem 1.5rem'};
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-transform: capitalize;
  transition: all 0.3s ease;

  span {
    color: #fff;
  }

  &:hover {
    background: #ad203e;
    transform: translateY(-0.5rem);
    border-color: #ad203e;
  }
`